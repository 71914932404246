<template>
    <div class="pd20 bg-w">
        <div class="xhm-table-gray mt20">
            <a-table rowKey="draft_id" :columns="columns" :pagination="pagination" @change="change"
                :data-source="list" :loading="loading">
                <template slot="create_time" slot-scope="record,index">
                    {{ timestampToTime(record.create_time) }}
                </template>
                <template slot="action" slot-scope="record,index">
                    <a-button type="link" @click="add(record)">添加到模板库</a-button>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { apiVersionDraft, apiVersionTemplateAdd } from '../../utils/js/api';
export default {
    data() {
        return {
            loading: false,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
            columns: [
                { title: '草稿ID', dataIndex: 'draft_id', align: 'center' },
                { title: '版本号', dataIndex: 'user_version', align: 'center' },
                { title: '描述', dataIndex: 'user_desc', align: 'center' },
                { title: '上传者', dataIndex: 'source_miniprogram', align: 'center' },
                { title: '提交时间', key: 'create_time', align: 'center', scopedSlots: { customRender: 'create_time' } },
                { title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action' } },
            ],
            list: [],
        }
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            if (this.loading == true) return;
            this.loading = true;
            apiVersionDraft().then(res => {
                this.pagination.total = res.draft_list.length;
                res.draft_list.sort((a,b)=>b.draft_id - a.draft_id);
                this.list = res.draft_list;
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },
        add(detail) {//添加
            apiVersionTemplateAdd({draft_id:detail.draft_id}).then(res => {
                console.log('res', res)
                this.$message.success('添加成功');
            })
        },
        change(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getList();
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1):date.getMonth()+1) + '-';
            var D = (date.getDate()< 10 ? '0'+date.getDate():date.getDate())+ ' ';
            var h = (date.getHours() < 10 ? '0'+date.getHours():date.getHours())+ ':';
            var m = (date.getMinutes() < 10 ? '0'+date.getMinutes():date.getMinutes()) + ':';
            var s = date.getSeconds() < 10 ? '0'+date.getSeconds():date.getSeconds();
            return Y+M+D+h+m+s;
        }
    }
}
</script>

<style></style>
